.landing, .terms, .privacy, .success {
  background: $blue;
  color: $white;
  font-family: 'Nexa', sans-serif;
}

.container {
  max-width: $xxl;
  margin: 0 auto;
  padding: 0 20px;
}

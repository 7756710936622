.landing {
  & .introduction {
    background: url('../../../pictures/introduction_background.png') no-repeat center bottom;
    background-size: cover;
    padding: 150px 0 30px;
    margin-top: -110px;
    min-height: 50vh;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: $lg) {
      padding: 200px 0 210px;
      min-height: 90vh;
    }

    .container {
      text-align: center;
    }

    .comingSoon {
      width: 250px;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url('../../../pictures/coming_soon_background.png') no-repeat center center;
      background-size: 100%;
      font-family: 'Integral CF', sans-serif;
      font-style: italic;
      color: $blue;
      text-transform: uppercase;
      padding: 0 10px 5px 0;
      margin: 0 auto;
    }

    h1 {
      font-family: 'Integral CF', sans-serif;
      font-weight: 600;
      color: $white;
      font-size: 36px;
      line-height: 32px;
      margin: 30px 0 20px;

      @media (min-width: $lg) {
        font-size: 60px;
        line-height: 48px;
        margin: 45px 0 30px;
      }
    }

    p {
      color: $gray-light;
      font-size: 13px;
      margin: 0;

      @media (min-width: $lg) {
        font-size: 16px;
      }
    }

    .btn {
      margin: 35px 0 0;
    }
  }
}

.landing, .terms, .privacy, .success {
  & .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: linear-gradient(to right,  #00ffaa 0%,#82ffa2 100%);
    font-family: 'Integral CF', sans-serif;
    font-weight: 600;
    color: $blue;
    text-transform: uppercase;
    text-decoration: none;
    padding: 16px 20px;
    border-radius: 30px;
    font-size: 14px;
    line-height: 14px;
    transition: all 300ms cubic-bezier(0.075, 0.820, 0.165, 1.000);
    
    &:hover {
      box-shadow: 0 0 5px 0 #00ffaa, 0 0 15px 0 #82ffa2;
    }
  }
}

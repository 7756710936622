.success {
  background: url('../../pictures/introduction_background.png') $blue no-repeat center top;
  background-size: 100%;
  min-height: 100vh;
  display: flex;

  @media (max-width: $lg) {
    background-size: 300%;
  }

  &__content {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  article {
    font-family: 'Nunito', sans-serif;
    padding: 80px 0;
    margin: auto 0;

    @media (max-width: $lg) {
      padding: 30px 0;
    }

    .container {
      max-width: $xl;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    h1 {
      font-family: 'Integral CF', sans-serif;
      font-weight: 600;
      color: $green-primary;
      font-size: 42px;
      line-height: 42px;
      margin: 30px 0 20px;
    }

    b {
      font-weight: 700;
    }

    a:not(.btn) {
      color: $green-primary;
      text-decoration: none;
      position: relative;

      &::after {
        content: "\0020";
        display: block;
        width: 0;
        height: 1px;
        background-color: $green-primary;
        position: absolute;
        left: 50%;
        bottom: -1px;
        transition: all 300ms cubic-bezier(0.075, 0.820, 0.165, 1.000);
      }

      &:hover {
        &::after {
          width: 100%;
          left: 0;
        }
      }
    }

    .btn {
      margin-top: 30px;
    }
  }

  .footer {
    background: $blue;
    margin-top: auto;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

@font-face {
  font-family: 'Nexa';
  src: url('../fonts/NexaBold.woff2') format('woff2'),
    url('../fonts/NexaBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Integral CF';
  src: url('../fonts/IntegralCF-DemiBold.woff2') format('woff2'),
      url('../fonts/IntegralCF-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Integral CF';
  src: url('../fonts/IntegralCF-RegularOblique.woff2') format('woff2'),
      url('../fonts/IntegralCF-RegularOblique.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Integral CF';
  src: url('../fonts/IntegralCF-Heavy.woff2') format('woff2'),
      url('../fonts/IntegralCF-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

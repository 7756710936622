.landing, .terms, .privacy, .success {
  & .header {
    padding: 35px 0;
    position: relative;
    z-index: 2;

    @media (max-width: $lg) {
      padding: 20px 0;
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .logo {
      @media (max-width: $lg) {
        max-width: 150px;

        img {
          max-width: 100%;
        }
      }
    }

    &__navigation {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        li {
          margin: 0 0 0 5px;
        }

        .btn {
          margin: 0 0 0 10px;
          padding: 10px 20px;

          @media (max-width: $lg) {
            display: none;
          }
        }
      }

      &__item {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $green-secondary;
        background: rgba($blue, .5);
        padding: 8px 12px;
        border-radius: 30px;
        transition: all 300ms cubic-bezier(0.075, 0.820, 0.165, 1.000);

        @media (max-width: $lg) {
          padding: 6px 10px;
        }

        &:hover {
          color: $green-primary;
          background: rgba($blue, .8);
        }
      }
    }
  }
}
